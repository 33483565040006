// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-js": () => import("./../src/templates/about_us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-careers-js": () => import("./../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cookies-policy-js": () => import("./../src/templates/cookiesPolicy.js" /* webpackChunkName: "component---src-templates-cookies-policy-js" */),
  "component---src-templates-download-content-js": () => import("./../src/templates/download_content.js" /* webpackChunkName: "component---src-templates-download-content-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landing-js": () => import("./../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-pricing-js": () => import("./../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-privacy-js": () => import("./../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-resource-js": () => import("./../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-sales-landing-js": () => import("./../src/templates/sales_landing.js" /* webpackChunkName: "component---src-templates-sales-landing-js" */),
  "component---src-templates-terms-js": () => import("./../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */),
  "component---src-templates-use-cases-js": () => import("./../src/templates/use_cases.js" /* webpackChunkName: "component---src-templates-use-cases-js" */)
}

