module.exports = [{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"wp.hubtype.com","protocol":"http","includeACF":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"KzU5nbkKnfyEiwyEeBEOeJ1AWbtG24Bp","devKey":"KzU5nbkKnfyEiwyEeBEOeJ1AWbtG24Bp","trackPage":true},
    }]
